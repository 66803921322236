import * as React from 'react'
import { GetProps, Label, Separator, SizeTokens, ThemeableStack, XStack, styled } from 'tamagui'
import { Field, FieldProps } from './Field'

const InputFieldFrame = styled(ThemeableStack, {
  flexDirection: 'row',
  variants: {
    unstyled: {
      false: {
        gap: '$4',
        px: '$7',
        br: '$true',
        borderWidth: 1,
        bc: '$backgroundTransparent',
        boc: '$borderColor',
        h: '$4',
        $gtXs: {
          h: '$4.5',
        },
      },
    },
    focussed: {
      true: {
        boc: '$borderColorFocus',
      },
    },
  } as const,
  defaultVariants: {
    unstyled: false,
  },
})

export type InputFieldProps = FieldProps &
  GetProps<typeof InputFieldFrame> & {
    size?: SizeTokens
    label?: React.ReactNode
    focussed?: boolean
    startView?: React.ReactNode
    endView?: React.ReactNode
  }

export function InputField({
  name,
  label,
  error,
  startView,
  endView,
  children,
  defaultError,
  ...otherProps
}: InputFieldProps) {
  return (
    <Field error={error} name={name} defaultError={defaultError} $platform-native={{ f: 1 }}>
      {label !== undefined && <Label htmlFor={name}>{label}</Label>}
      <InputFieldFrame {...otherProps} jc="center" ai="center">
        {startView && (
          <XStack gap="$4">
            {startView}
            <Separator alignSelf="stretch" vertical />
          </XStack>
        )}
        {children}
        {endView && <XStack>{endView}</XStack>}
      </InputFieldFrame>
    </Field>
  )
}
