import { guestOnlyGetSSP } from '@/utils/guestOnly'
import { getServerSide } from '@/utils/serverSideFunction/server-side'
import { GetBlogRecent } from '@my/api'
import { LoginScreen } from 'galaxycard/features/auth/login-screen'
import { GetServerSidePropsContext } from 'next'
import Head from 'next/head'

const Page = () => {
  return (
    <>
      <Head>
        <title>Log in</title>
      </Head>
      <LoginScreen />
    </>
  )
}

export const getServerSideProps = guestOnlyGetSSP(async (ctx: GetServerSidePropsContext) => {
  return getServerSide(
    {
      query: GetBlogRecent,
    },
    ctx
  )
})

export default Page
