import { useFormikContext } from 'formik'
import * as React from 'react'
import { NativeSyntheticEvent, TextInput, TextInputFocusEventData } from 'react-native'
import { Input as TInput, InputProps as TInputProps, styled } from 'tamagui'
import { bugsnag } from '../utils/bugsnag'
import { analytics } from '../utils/firebase/analytics'
import { InputField } from './InputField'

const INPUT_NAME = 'Input'

export type InputBaseProps = TInputProps & {
  label?: React.ReactNode
  // onPress?: (event: GestureResponderEvent) => unknown
  startView?: React.ReactNode
  endView?: React.ReactNode
  name: string
  defaultError?: boolean
}

export type InputProps = Omit<InputBaseProps, 'onChangeText'> & {
  onChangeText?: (value: string) => string | void
}

export const InputFrame = styled(TInput, {
  name: INPUT_NAME,
  bw: 0,
  bc: 'transparent',
  br: 0,
  focusStyle: {
    outlineWidth: 0,
    bw: 0,
  },
  px: 0,
  h: 'auto',
  fontSize: '$9',
  fontWeight: '$3',
  f: 1,
  $gtXs: {
    fontSize: '$12',
  },
})

export const InputBase = React.forwardRef<TextInput, InputBaseProps>(
  (
    {
      label,
      startView,
      endView,
      onFocus,
      onBlur,
      editable,
      name,
      onChangeText,
      autoFocus,
      defaultError = false,
      ...textInputProps
    },
    ref
  ) => {
    const [focussed, setFocussed] = React.useState(autoFocus ?? false)

    const formik = useFormikContext<string>()

    const id = React.useId()

    const focusHandler = React.useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        analytics.logEvent('focus', {
          target: name,
        })
        bugsnag().then((client) => client.leaveBreadcrumb('Focus', { target: name }, 'process'))
        setFocussed(true)
        onFocus?.(e)
      },
      [name, onFocus]
    )

    const blurHandler = React.useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        analytics.logEvent('blur', {
          target: name,
        })
        bugsnag().then((client) => client.leaveBreadcrumb('Blur', { target: name }, 'process'))
        setFocussed(false)
        onBlur?.(e)
        formik?.setFieldTouched?.(name, true, true)
        formik?.handleBlur?.(name)?.(e)
      },
      [formik, name, onBlur]
    )

    const changeHandler = React.useCallback(
      (value: string) => {
        onChangeText?.(value)
        analytics.logEvent('type', {
          target: name,
          value,
        })
        bugsnag().then((client) =>
          client.leaveBreadcrumb(
            'Type',
            {
              target: name,
              value,
            },
            'process'
          )
        )
      },
      [name, onChangeText]
    )

    const { error, value, touched } = formik?.getFieldMeta<string | undefined>(name) || {}

    return (
      <InputField
        label={label}
        focussed={focussed ? true : undefined}
        startView={startView}
        endView={endView}
        error={touched && error && (formik?.submitCount ?? 0) > 0 ? error : undefined}
        name={name}
        defaultError={defaultError}
      >
        <InputFrame
          ref={ref}
          onFocus={focusHandler}
          onBlur={blurHandler}
          onChangeText={changeHandler}
          editable={editable}
          value={value?.toString?.()}
          id={id}
          autoFocus={focussed}
          mih="$2.25"
          {...textInputProps}
        />
      </InputField>
    )
  }
)

InputBase.displayName = INPUT_NAME

export const Input = React.forwardRef<TextInput, InputProps>(
  ({ label, startView, endView, name, onChangeText, autoFocus, ...textInputProps }, ref) => {
    const formik = useFormikContext<string>()

    const changeHandler = React.useCallback(
      (value: string) => {
        if (onChangeText) {
          const newValue = onChangeText(value)
          if (newValue !== undefined) {
            value = newValue
          }
        }
        formik?.setFieldTouched?.(name, true, false)
        formik?.handleChange?.(name)?.(value)
      },
      [formik, name, onChangeText]
    )

    const handleSubmitEditing = React.useCallback(() => {
      formik?.handleSubmit()
    }, [formik])

    return (
      <InputBase
        label={label}
        autoFocus={autoFocus}
        startView={startView}
        endView={endView}
        name={name}
        onSubmitEditing={handleSubmitEditing}
        onChangeText={changeHandler}
        ref={ref}
        {...textInputProps}
        defaultError={true}
      />
    )
  }
)

Input.displayName = INPUT_NAME
