import { useMutation } from '@apollo/client'
import { UserLogin, UserOTP } from '@my/api'
import { Button, Formik, FormikProps, Input, LabelText, tamagui } from '@my/ui'
import * as React from 'react'
import { YStackProps } from 'tamagui'
import * as Yup from 'yup'
import useScreenNavigation from '../../../hooks/useScreenNavigation'

const { XStack, YStack } = tamagui

type Props = YStackProps & {
    phone: string
    onChangeNumber: () => void
}

export const OtpForm = ({ phone, onChangeNumber, ...props }: Props) => {
    const [userOTPMutateFunction, { data, loading, error }] = useMutation(UserOTP)
    const [userLoginMutateFunction, { loading: resendLoading }] = useMutation(UserLogin)

    const values = React.useMemo(
        () => ({
            otp: '',
        }),
        []
    )

    const formikRef = React.useRef<FormikProps<typeof values>>(null)

    const handleOtpSubmit = React.useCallback(
        ({ otp }: typeof values) => {
            return userOTPMutateFunction({ variables: { phone, otp } })
        },
        [phone, userOTPMutateFunction]
    )

    const handleResendOtp = React.useCallback(() => {
        return userLoginMutateFunction({ variables: { phone } }).catch(() => true)
    }, [phone, userLoginMutateFunction])

    const { navigateToUrl } = useScreenNavigation()

    React.useEffect(() => {
        if (data?.sessionPut) {
            navigateToUrl(data.sessionPut.url)
        }

        if (error && formikRef.current) {
            formikRef.current.setErrors({
                otp: error?.message === 'invalid_otp' ? 'Invalid OTP' : 'Unknown Error',
            })
        }
    }, [data, error, navigateToUrl])

    const validationSchema = Yup.object().shape({
        otp: Yup.string().length(6, 'Please enter a valid OTP').required('OTP is required'),
    })

    return (
    
        <Formik
            initialValues={values}
            onSubmit={handleOtpSubmit}
            validationSchema={validationSchema}
            validateOnMount
            validateOnChange
            innerRef={formikRef}
        >

            <YStack gap="$10" $gtMd={{ gap: '$14' }} f={1} {...props}>

                <YStack>
                    <Input
                        style={{ width: '100%' }}
                        keyboardType="number-pad"
                        maxLength={6}
                        numberOfLines={1}
                        label={'Enter OTP'}
                        placeholder={'Enter OTP'}
                        returnKeyType="go"
                        name="otp"
                    />
                </YStack>
                <Button code={'verify-otp'} disabled={loading}>
                    <Button.Text>{loading ? 'Submitting...' : 'Verify OTP'}</Button.Text>
                </Button>
                <YStack ai="center">
                    <XStack gap={'$1'}>
                        <LabelText>OTP sent to +91-{phone}. </LabelText>
                        <LabelText
                            onPress={onChangeNumber}
                            color={'$aliceBlue10'}
                            cursor="pointer"
                            userSelect="none"
                        >
                            Change
                        </LabelText>
                    </XStack>
                    <XStack gap={'$1'}>
                        <LabelText>OTP not received?</LabelText>
                        <LabelText
                            onPress={handleResendOtp}
                            color={'$aliceBlue10'}
                            cursor="pointer"
                            userSelect="none"
                            disabled={resendLoading}
                        >
                            Resend OTP
                        </LabelText>
                    </XStack>
                </YStack>
            </YStack>
        </Formik>
    )
}