import { ThemeableStack, ThemeableStackProps, withStaticProperties } from 'tamagui'

export function SignupPanelFrame({ $gtXs, ...props }: ThemeableStackProps) {
  return (
    <ThemeableStack
      fd="row"
      f={1}
      $gtXs={{
        elevation: 12,
        shadowRadius: '$2',
        shadowOffset: { width: 0, height: 1 },
        shac: '$shadowColor',
        ...$gtXs,
      }}
      br="$2"
      overflow="hidden"
      {...props}
    />
  )
}

const ImagePanel = ({ $gtXs, ...props }: ThemeableStackProps) => {
  return (
    <ThemeableStack
      dsp="none"
      p="$18"
      f={0.4}
      fb={0}
      $gtXs={{ dsp: 'flex', ...$gtXs }}
      fd="column"
      theme="inverse"
      backgroundColor="$background"
      {...props}
    />
  )
}

const ContentPanel = ({ $gtXs, $gtMd, ...props }: ThemeableStackProps) => {
  return (
    <ThemeableStack
      fd="column"
      p="$8"
      fb={0}
      f={1}
      $gtXs={{ p: '$10', ...$gtXs, f: 0.6, fb: 0 }}
      $gtMd={{ p: '$14', ...$gtMd, f: 0.6, fb: 0 }}
      {...props}
    />
  )
}

export const SignupPanel = withStaticProperties(SignupPanelFrame, {
  ImagePanel,
  ContentPanel,
})
