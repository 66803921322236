import { useMutation } from '@apollo/client'
import { UserLogin } from '@my/api'
import { BodyText, Button, Checkbox, Formik, FormikProps, Input, LabelText, tamagui } from '@my/ui'
import { Check as CheckIcon } from '@tamagui/lucide-icons'
import * as React from 'react'
import { YStackProps } from 'tamagui'
import * as Yup from 'yup'

const { YStack, Label } = tamagui

const MOBILE_PREFIX = <BodyText>+91</BodyText>

type Props = YStackProps & {
  phone: string
  onSendOtp: (phone: string) => void
}

export const LoginForm = ({ phone, onSendOtp, ...props }: Props) => {
  const [mutateFunction, { loading, error }] = useMutation(UserLogin)

  const formikRef = React.useRef<FormikProps<typeof values>>(null)

  const values = React.useMemo(
    () => ({
      phone,
      tnc: false,
    }),
    [phone]
  )

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .transform((currentValue) => currentValue.replace(/\D/g, '')?.slice(-10))
      .length(10, 'Incorrect Phone Number')
      .required('Phone number is required'),
    tnc: Yup.boolean().isTrue().required(),
  })

  const handleLoginSubmit = React.useCallback(
    ({ phone }: typeof values) => {
      return mutateFunction({ variables: { phone } }).then(() => onSendOtp(phone))
    },
    [mutateFunction, onSendOtp]
  )

  const handlePhoneChange = React.useCallback(
    (value: string) => value.replace(/[^0-9+]/g, '').replace(/^\+91/, ''),
    []
  )

  const checkboxLabel = React.useMemo(() => {
    return (
      <Label>
        <LabelText>
          {'By continuing, you are indicating that you have read and agree to our '}
          <LabelText href="/tnc" color={'$aliceBlue10'}>
            terms of use
          </LabelText>
          {' & '}
          <LabelText href="/privacy" color={'$aliceBlue10'}>
            privacy policy
          </LabelText>
        </LabelText>
      </Label>
    )
  }, [])

  return (
    <Formik
      initialValues={values}
      onSubmit={handleLoginSubmit}
      validationSchema={validationSchema}
      validateOnMount
      validateOnChange
      innerRef={formikRef}
    >
      <YStack gap="$10" $gtMd={{ gap: '$14' }} f={1} {...props}>
        <Input
          style={{ width: '100%' }}
          label={'Phone Number'}
          keyboardType="phone-pad"
          maxLength={10}
          numberOfLines={1}
          startView={MOBILE_PREFIX}
          placeholder={'Phone Number'}
          returnKeyType="go"
          onChangeText={handlePhoneChange}
          name="phone"
        />
        <Checkbox name="tnc" label={checkboxLabel}>
          <Checkbox.Indicator backgroundColor={'$background'}>
            <CheckIcon />
          </Checkbox.Indicator>
        </Checkbox>
        <Button code={'phone-login'} disabled={loading}>
          <Button.Text>{loading ? 'Submitting...' : 'Send OTP'}</Button.Text>
        </Button>
      </YStack>
    </Formik>
  )
}