import * as React from 'react'
import { useRouter } from 'solito/router'
import { useSearchParams } from '../utils/usePathname'

export default function useScreenNavigation() {
  const router = useRouter()

  const { redirect } = useSearchParams()

  const navigateToUrl = React.useCallback(
    (url?: string) => {
      if (url !== undefined && url !== null) {
        const editedUrl = new URL(url, 'http://localhost')
        if ((redirect ?? '') !== '') {
          editedUrl.searchParams.set('redirect', redirect as string)
        }
        router.replace(`${editedUrl.pathname}${editedUrl.search}`, undefined, {
          experimental: { nativeBehavior: 'stack-replace', isNestedNavigator: false },
        })
      } else if ((redirect ?? '') !== '') {
        router.replace(redirect as string)
      } else {
        router.replace('/')
      }
    },
    [redirect, router]
  )

  return {
    navigateToUrl,
  }
}
