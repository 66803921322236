import { BodyText, H4, SignupPanel, tamagui } from '@my/ui'
import { AnimatePresence } from 'moti'
import * as React from 'react'
import { SolitoImage } from 'solito/image'
import type { ThemeableStackProps } from 'tamagui'
import Login from '../../../assets/login.svg'
import { Title } from '../../components/Title'
import { ViewPort } from '../../components/Viewport'
import { LoginForm } from './components/LoginForm'
import { OtpForm } from './components/OtpForm'

const { Stack, YStack } = tamagui

export const LoginScreen = ({ $gtXs, $gtMd, ...props }: ThemeableStackProps) => {
  const [phone, setPhone] = React.useState<string>('')

  const [isEditing, setEditing] = React.useState(false)

  const startEditing = () => {
    setEditing(true)
  }

  const cancelEditing = (phone: string) => {
    setEditing(false)
    setPhone(phone)
  }

  return (
    <>
      <Title title="Login" />
      <ViewPort
        $gtXs={{ px: '$22', py: '$40', ...$gtXs }}
        $gtMd={{ px: '$26', ...$gtMd }}
        {...props}
      >
        <SignupPanel $gtXs={{ mt: '$14', mb: '$17' }}>
          <SignupPanel.ImagePanel gap="$19">
            <YStack mb="$2">
              <H4 ta="center">Login</H4>
              <BodyText ta="center">Please enter your phone number to continue</BodyText>
            </YStack>
            <Stack width="100%" aspectRatio={1.4}>
              <SolitoImage src={Login} alt="Login" fill contentFit="fill" />
            </Stack>
          </SignupPanel.ImagePanel>
          <SignupPanel.ContentPanel>
            {(isEditing || phone === '') && (
              <AnimatePresence>
                <LoginForm
                  animation="quick"
                  enterStyle={{ x: -6, opacity: 0 }}
                  exitStyle={{ x: -6, opacity: 0 }}
                  x={0}
                  opacity={0.9}
                  phone={phone}
                  onSendOtp={cancelEditing}
                />
              </AnimatePresence>
            )}
            {!isEditing && phone !== '' && (
              <AnimatePresence>
                <OtpForm
                  animation="quick"
                  enterStyle={{ x: -6, opacity: 0 }}
                  exitStyle={{ x: -6, opacity: 0 }}
                  x={0}
                  opacity={0.9}
                  phone={phone}
                  onChangeNumber={startEditing}
                />
              </AnimatePresence>
            )}
          </SignupPanel.ContentPanel>
        </SignupPanel>
      </ViewPort>
    </>
  )
}
